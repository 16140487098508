<template>
  <div>
    <MyHeader />
    <main id="content">
      <nav class="user-tab-nav">
        <div class="container">
          <ProfileMenu />
        </div>
      </nav>
      <div class="container mt-4 mt-lg-5">
        <BookingInfo />
        <div class="row mb-4 mb-lg-5">
          <div class="col-12">
            <UserTripMenu />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-lg-6 mb-4">
            <div class="row justify-content-between">
              <div class="col-12 col-md-auto">
                <h3 class="min-title">
                  Itinerary
                </h3>
              </div>
              <div class="col-12 col-md-auto print-wrap">
                <p class="mb-0">
                  <small>Atlantic Standard Time (AST)</small>
                </p>
                <a href="javascript:;"
                   @click="printPage">
                  <i class="icon-print" />
                  Print
                </a>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-12 col-md-auto">
                <p>Your concierge will help you create the itinerary for your trip.</p>
              </div>
            </div>
            <div :class="{ 'itinerary-day': itinerary.activities.length > 0, 'itinerary-day-btn': !itinerary.activities.length > 0 }"
                 v-for="(itinerary, index) in itineraries"
                 :key="index">
              <h5>{{ itinerary.date }}</h5>
              <ul>
                <li v-for="(activity, _index) in itinerary.activities"
                    :key="_index">
                  <div class="activity">
                    <span :class="{ badge: true, 'badge-primary': activity.status == 1, 'badge-dark': activity.status == 0 }">{{ activity.status == 1 ? 'Confirmed' : 'Pending' }}</span>
                    <div class="info">
                      <small>{{ activity.itinerary_time }}</small>
                      <strong>{{ activity.description }}</strong>
                      <span v-if="activity.note">{{ activity.note }}</span>
                    </div>
                    <div class="img"
                         v-if="activity.experience && activity.experience.image">
                      <img :src="activity.experience.image.path">
                    </div>
                    <!-- <div class="edit">
                      <div>
                        <a href="#!" class="btn"><i class="icon-edit"></i> Edit this activity</a>
                        <a href="#!" class="remove">or Remove</a>
                      </div>
                    </div> -->
                  </div>
                </li>
              </ul>
              <!-- <a href="javascript:;" class="add-btn"><span>+</span> Add Activities</a> -->
            </div>
          </div>
          <div class="col justify-content-center d-none d-lg-flex">
            <div class="h-100 border-left" />
          </div>
          <div class="col-12 col-lg-5"
               v-if="booking && booking.property && booking.property.list_experiencies.length > 0">
            <h3 class="min-title">
              Experiences & Acitivities for your trip
            </h3>
            <ul class="acitivities-list">
              <li v-for="(experience, index) in booking.property.list_experiencies"
                  :key="index">
                <div class="thumb"
                     v-if="experience && experience.image">
                  <a href="javascript:;"
                     @click="openExperience(experience);">
                    <i class="fas fa-search" />
                    <img :src="experience.image.path">
                  </a>
                </div>
                <div class="info">
                  <h5>
                    <a href="javascript:;">{{ experience.title }}</a>
                  </h5>
                  <p>{{ experience.state.name }}, {{ experience.country.name }}</p>
                  <router-link class="btn btn-primary btn-sm"
                               v-if="booking.status !== 3"
                               :to="{ name: 'user-message', params: { id: booking.id }}">
                    Tell your Concierge
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal d-block"
           style="opacity: 1;"
           v-if="isModalOpen"
           id="activityModal">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    @click="isModalOpen = false;"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-slide">
              <div class="slide">
                <img :src="modalExperience.image.path"
                     class="img-fluid">
              </div>
              <!-- <div class="slide">
                <img data-flickity-lazyload="img/modal-slide01.jpg"
                     src="/img/modal-slide01.jpg">
              </div> -->
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-lg-7">
                  <h2>{{ modalExperience.title }} <small>{{ modalExperience.state.name }}</small></h2>
                </div>
                <div class="col-12 col-lg-7 modal-experience-description"
                     v-html="modalExperience.description" />
                <div class="col-1 justify-content-center d-none d-lg-flex">
                  <div class="h-100 border-left" />
                </div>
                <div class="col-12 col-lg">
                  <h3>Are you interested?</h3>
                  <!-- <p><small>US$50 / Traveler</small></p> -->
                  <router-link
                    class="btn btn-primary btn-sm"
                    :to="{ name: 'user-message', params: { id: this.$route.params.id }}">
                    Tell your Concierge
                  </router-link>
                  <!-- <hr>
                  <h3>Departure schedule:</h3>
                  <ul class="list-unstyled">
                    <li><small>8:00 AM</small></li>
                    <li><small>10:00 AM</small></li>
                    <li><small>2:00 PM</small></li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import UserTripMenu from '../../components/users/UserTripMenu.vue';
import BookingInfo from '../../components/users/BookingInfo.vue';

export default {
  name: 'UserMessageDetail',
  components: {
    ProfileMenu,
    UserTripMenu,
    BookingInfo
  },
  data() {
    return {
      // booking: {},
      isModalOpen: false,
      itineraries: {},
    };
  },
  methods: {
    printPage() {
      window.print();
    },
    openExperience(experience) {
      this.isModalOpen = true;
      this.modalExperience = experience;
    }
  },
  computed: {
    booking() {
      return this.$store.getters.getBookingTrip;
    }
  },
  created() {
    setTimeout(() => {
      const { status } = this.$store.getters.getBookingTrip;
      if (status === 2 || status === 5) {
        this.$router.push({ name: 'user-trip-overview', params: { id: this.$route.params.id } });
      } else if (!this.$route.params.id) {
        this.$router.push('/');
      }
    }, 500);
    // this.booking = JSON.parse(this.$localStorage.get('bookingTrip'));
    this.$axios.get(`/v1/bookings/${this.$route.params.id}/itineraries`).then((response) => {
      this.itineraries = response.data.data;
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
